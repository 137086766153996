import React from 'react';

import Highlighter from 'react-highlight-words';
import { useAppState } from 'shared/state';
import { EntityDraft } from '../../pages/GroupChat/types';
import clsx from 'clsx';
import { AiFillAudio, AiFillFile } from 'react-icons/ai';
import { IoLocationSharp } from 'react-icons/io5';
import { MdContactPhone } from 'react-icons/md';
import { RiVideoFill } from 'react-icons/ri';
import { BsFillImageFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';
import { useLocation } from 'react-router';

export function textTruncateSearch(str = '', searchStr: string, length: number, ending?: string) {
	if (length == null) {
		length = 100;
	}
	if (ending == null) {
		ending = '...';
	}
	if (str && str.length > length) {
		return str.substring(0, length - searchStr.length) + ending;
	} else {
		return str;
	}
}
export function textTruncate(str = '', length: number, ending?: string) {
	if (!length) {
		length = 100;
	}
	if (!ending) {
		ending = '...';
	}
	if (str && str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	} else {
		return str;
	}
}
export const normalizeSearchResult = (msg: string, searchQuery: string, strLength: number) => {
	const lowerSearchQuery = searchQuery && searchQuery.toLowerCase();
	const lowerMsg = msg && msg.toLowerCase();
	const searchIndex = lowerMsg.indexOf(lowerSearchQuery);
	if (!String.prototype.startsWith) {
		Object.defineProperty(String.prototype, 'startsWith', {
			enumerable: false,
			configurable: false,
			writable: false,
			value: function (searchString: string, position: number) {
				position = position || 0;
				return this.indexOf(searchString, position) === position;
			}
		});
	}

	let normalizedText;

	if (searchIndex === -1) {
		return msg;
	}
	if (lowerMsg.startsWith(lowerSearchQuery)) {
		normalizedText = msg;
	} else if (msg.length <= strLength) {
		normalizedText = msg;
	} else if (msg.length > strLength) {
		const tempStr = msg.slice(searchIndex);
		if (tempStr.length > strLength) {
			normalizedText = `...${textTruncate(tempStr, strLength)}`;
		} else {
			normalizedText = `...${textTruncateSearch(
				msg.slice(-strLength),
				lowerSearchQuery,
				strLength
			)}`;
		}
	} else {
		normalizedText = msg;
	}

	return normalizedText.replace(/\n/g, ' ');
};

type Props = {
	last_message_type: string | null;
	last_message_text: string;
	last_message_author: {
		first_name: string;
		last_name: string;
	};
	draftMessage?: EntityDraft;
};

const FileMessage = ({ icon, text }: { icon: React.ReactNode; text: string }) => {
	const { isRTL } = useTranslationHelpers();
	return (
		<div className={'flex items-center'}>
			<span className={'font-semibold text-kblue'}>{icon}</span>
			<span className={'mx-2 font-light'}>{text}</span>
		</div>
	);
};

export function LastMessageView({
	last_message_type,
	last_message_text,
	last_message_author,
	draftMessage
}: Props) {
	const { t } = useTranslation();
	const lastMessageAuthor = `${last_message_author.first_name[0].toUpperCase()}${last_message_author.last_name[0].toUpperCase()}`;
	const { searchQuery: caseSearchQuery, groupSearchQuery } = useAppState();
	const location = useLocation();

	const searchQuery = location.pathname.includes('case-messages')
		? caseSearchQuery
		: groupSearchQuery;

	const getLastMessage = (last_message_text: string, last_message_type: string | null) => {
		if (searchQuery) {
			const normalizedText = normalizeSearchResult(last_message_text, searchQuery, 25);
			const searchWords = searchQuery.split(/\s/).filter((word: string) => word);

			return (
				<Highlighter
					highlightClassName="highlighted"
					searchWords={searchWords}
					autoEscape
					textToHighlight={normalizedText}
				/>
			);
		}

		if (draftMessage) {
			return <span className={'text-kred-pink'}>{draftMessage.text}</span>;
		}

		switch (last_message_type?.toLowerCase()) {
			case 'audio':
				return <FileMessage icon={<AiFillAudio />} text={t('audio_message')} />;
			case 'video':
				return <FileMessage icon={<RiVideoFill />} text={t('video_message')} />;
			case 'location':
				return <FileMessage icon={<IoLocationSharp />} text={t('location_message')} />;
			case 'file':
				return <FileMessage icon={<AiFillFile />} text={t('file_message')} />;
			case 'contact':
				return <FileMessage icon={<MdContactPhone />} text={t('contact_message')} />;
			case 'image':
				return <FileMessage icon={<BsFillImageFill />} text={t('image_message')} />;
			default:
				return last_message_text;
		}
	};

	return (
		<div className={'flex items-center overflow-hidden text-sm'}>
			<div className={clsx('user-name font-semibold text-kblue', { uppercase: !draftMessage })}>
				{draftMessage ? t('draft') : lastMessageAuthor}:
			</div>
			<div className="last-message mx-2 truncate font-light transition-colors dark:text-white">
				{getLastMessage(last_message_text, last_message_type)}
			</div>
		</div>
	);
}
